import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const PageSEO = dynamic(() => import('@/components/SEO/PageSEO'));

export default function HeadTag({ data, title, type }) {
    const [themeColor, setThemeColor] = useState('#ffffff');

    useEffect(() => {
        // Initial theme detection
        const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const localStorageTheme = localStorage.getItem('theme');

        if (localStorageTheme === 'dark' || darkModeQuery.matches) {
            setThemeColor('#000000');
        } else {
            setThemeColor('#ffffff');
        }

        const handleThemeChange = e => {
            if (e.matches) {
                setThemeColor('#000000');
            } else {
                setThemeColor('#ffffff');
            }
        };

        // Listen for theme changes
        darkModeQuery.addListener(handleThemeChange);

        // Clean up the listener on component unmount
        return () => darkModeQuery.removeListener(handleThemeChange);
    }, []);

    return (
        <>
            <PageSEO title={title} data={data} type={type} />
            <Head>
                {/* ... rest of your head content ... */}
                <meta name="theme-color" content={themeColor} />
                <meta name="msapplication-TileColor" content={themeColor} />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="publisher" content="Jews For Jesus" />
                <meta
                    name="keywords"
                    content="messianic prophecies, jews for jesus store, last week of jesus' life day by day, how many years between daniel and jesus, jewsforjesus, what religion was jesus, jews for jesus near me, arielle randle, messianic prophecy, messianic jews, jesus last week, jew for jesus, which cup did jesus take at passover, the last week of jesus life, yeshu, last week of jesus life, do jews believe in jesus christ, why do jews not believe in jesus, jews for jesus san francisco, prophecies of the messiah, jews for jesus testimonies, is jesus god, jews for jesus podcast, jews for jesus blog"
                />
                {/* ... rest of your head content ... */}
            </Head>
        </>
    );
}
