import { useEffect, useState } from 'react';

export default function useDonationView() {
    const [donationViewCookie, setDonationViewCookie] = useState(false);

    useEffect(() => {
        const findDonationCookie = document.cookie.split('; ').find(row => row.startsWith('j4jdonationViewV2=true'));
        const findNewsletterCookie = document.cookie.split('; ').find(row => row.startsWith('j4jNewsletterView=true'));
        const findSubPageCookie = document.cookie.split('; ').find(row => row.startsWith('j4jSubGivePageView=true'));
        const findConversionCookie = document.cookie.split('; ').find(row => row.startsWith('j4jGiftConversionThankyou=true'));
        if ((findDonationCookie || findSubPageCookie || findNewsletterCookie) && !findConversionCookie) setDonationViewCookie(true);
    }, []);

    return donationViewCookie;
}
