import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import cx from 'classnames';
import { useAppTheme } from '@/components/GlobalAppTheme';
import Header from '@/components/Header';
import HeadTag from '@/components/Head';
import PageHeader from '@/components/PageHeader';

const RelatedPosts = dynamic(() => import('@/components/RelatedPosts'));
const Footer = dynamic(() => import('@/components/Footer/Footer'));
// const FooterSubscription = dynamic(() => import('@/components/FooterSubscription'), { ssr: false });
const Lightbox = dynamic(() => import('@/components/Lightbox'), { ssr: false });
const Modules = dynamic(() => import('@/components/Modules'), { ssr: true });

import useDonationView from '@/hooks/useDonationView';

export default function PageLayout({ layoutData }) {
    const { acfSections, title, content, pageHeaderSettings, uri, tags } = layoutData;
    const { hideHeader, hideFooter, hideMakeMyGift } = pageHeaderSettings;
    const relatedPosts = pageHeaderSettings?.postSettings?.relatedPosts;
    const sections = acfSections?.sections || [];
    const isTransparentHeader = pageHeaderSettings?.transparentHeader;
    const donationView = useDonationView();
    // Excluding certain categories and their posts from showing the Donate button in the header
    const noDonationLinkPages = uri.startsWith('/learn/') || uri.startsWith('/stories/') || uri.startsWith('/jewish-resources/');
    // Theme settings
    const theme = useAppTheme();
    const noLightboxUrls = theme?.noLightboxUrls?.split(', ');

    const noLightboxPage = noLightboxUrls.find(urlString => uri.startsWith(urlString));
    // The lightbox will not show if there is a "no-lightbox" tag in WP
    const noLightboxTag = tags?.nodes?.find(node => node.name === 'no-lightbox');
    const showLightbox = !noLightboxTag && !noLightboxPage && (theme.specialTimesToShowLightbox || donationView);

    useEffect(() => {
        if (uri === '/give' || uri === '/giving') {
            document.cookie = 'j4jdonationViewV2=true; max-age=86400; path=/';
            sessionStorage.setItem('LightboxShow', 'false');
        } else if (uri === '/thank-you') {
            document.cookie = 'j4jGiftConversionThankyou=true; max-age=1209600; path=/';
            sessionStorage.setItem('LightboxShow', 'false');
        } else if (uri === '/thank-you-and-more') {
            document.cookie = 'j4jContact=2110-TY; max-age=34560000; path=/';
        }
    }, [uri]);

    return (
        <>
            {showLightbox && <Lightbox />}
            <div className="page-container">
                <HeadTag title={title} type="page" data={layoutData} />
                {!hideHeader && <Header isTransparentHeader={isTransparentHeader} hideMakeMyGift={hideMakeMyGift || noDonationLinkPages} />}
                <PageHeader type="page" title={title} {...pageHeaderSettings} />
                {sections?.map((section, index) => (
                    <Modules key={index} moduleData={section} moduleIndex={`module-page-${index}`} type="Page" />
                ))}
                {content && <div className="mx-auto h-[700px]">{content}</div>}
                {/* {!hideFooter && <Share title={title} uri={uri} />} */}
                {relatedPosts && <RelatedPosts relatedPosts={relatedPosts} />}
                {!hideFooter && <Footer />}
            </div>
        </>
    );
}
